import "../afterpay/afterpay.css";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader } from "../loader/Loader";
import Swal from "sweetalert2";
import { getTraveller } from "../../Reducers/authSlice";
import { useAppDispatch, useAppSelector } from "../../hook";

const PayAfter = () => {
  const dispatch = useAppDispatch();
  const storedId = localStorage.getItem("id");
  const id = storedId ?? "";
  let checkoutId = "";
  const { link_id } = useParams();
  const [checkoutData, setCheckoutData] = useState<any>(null);
  const [panNumber, setPanNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [domestincorInternational, setDomesticorInternational] =
    useState<any>(null);
  const [state, setState] = useState<any>(null);
  const [gst, setGst] = useState<any>(null);
  const [tcs, setTcs] = useState<any>(null);
  const [paymentType, setPaymentType] = useState<any>("Complete");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [otherChareges, setOtherCharges] = useState<any>(null);
  const [loader, setLoader] = useState<any>(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [afterCouponPrice, setAfterCouponPrice] = useState<number>(0);
  const [loadAfterPay, setLoadAfterPay] = useState<any>(null);
  const navigate = useNavigate();
  let travellerCounts = checkoutData?.getData?.travel_no_of_traveler;
  let value = travellerCounts?.toString();
  setTimeout(() => {
    localStorage.setItem("TravellerCounts", value);
  }, 50);
  useEffect(() => {
    AllotherCharges();
    loadAfterPayData();
  }, []);
  const loadCheckoutData = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/get-appliedTourById`,
      {
        id: checkoutId,
      }
    );

    if (response?.data?.success) {
      setCheckoutData(response?.data);
      setAfterCouponPrice(response?.data?.getData?.finalPrice);
      const travellerDetails = {
        value: response?.data?.getData,
      };
      dispatch(getTraveller(travellerDetails));
      setDomesticorInternational(
        response?.data?.getData?.tour_id?.domesticorInternational
      );
      setState(response?.data?.getData.user_id.state);
      setGst(response?.data.applyTourbatchId[0].gst);
      setTcs(response.data.applyTourbatchId[0].tcs);
    }
  };

  const loadAfterPayData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/get-booked-tour/${link_id}`
    );

    if (response?.data?.success) {
      checkoutId = response?.data?.getData?._id;

      await loadCheckoutData();

      setLoadAfterPay(response?.data.getData);
    }
  };
  const handleChange = (e: any) => {
    setPanNumber(e.target.value);
  };
  const tourType = loadAfterPay?.tour_type;

  let OrderNote = loadAfterPay?.tour_id.orderNote;

  const finalPrice = loadAfterPay?.finalPrice || 0;
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  function formatTotalAmount(amount: any) {
    amount = parseFloat(amount);

    if (isNaN(amount)) {
      return "0";
    }

    return amount.toLocaleString("en-IN");
  }
  const checkoutResponse = localStorage.getItem("checkoutResponse");

  let objId = "";
  if (checkoutResponse) {
    const parsedCheckoutResponse = JSON.parse(checkoutResponse);
    objId = parsedCheckoutResponse.objId;
  }

  const boardingPrice = (city: string, type: string) => {
    if (checkoutData?.applyTourbatchId?.length) {
      let tourData = checkoutData?.applyTourbatchId[0];
      let boardingArr = JSON.parse(tourData?.boarding_point_price);
      let boardingCityObj = boardingArr.find((o: any) => o.bp === city);
      let boardingPriceIndex = boardingCityObj?.bp_price.findIndex(
        (o: any) => Object.keys(o)[0] == type
      );
      return boardingCityObj?.bp_price?.[boardingPriceIndex]?.[type];
    } else {
      return 0;
    }
  };

  let SGSTamount = checkoutData?.getData?.amtWithSGSTNewCharges;
  let CGSTamount = checkoutData?.getData?.amtWithCGSTNewCharges;
  let IGSTamount = checkoutData?.getData?.amtWithIGSTNewCharges;
  let TCSamount = checkoutData?.getData?.amtWithTCSNewCharges;

  const totalAmount = () => {
    let totalPackageAmount =
      checkoutData?.getData?.travel_packageType_data?.reduce(
        (sum: number, item: any) => sum + item.persons * item.packPrice,
        0
      );

    let totalRoomAmount =
      checkoutData?.getData?.travel_roomSharing_data?.reduce(
        (sum: number, item: any) => sum + item.persons * item.roomPrice,
        0
      );
    let totalKidsAmount =
      checkoutData?.getData?.child_price * checkoutData?.getData?.child_count;
    let totalInfantAmount =
      checkoutData?.getData?.infant_count * checkoutData?.getData.infant_price;
    return (
      totalPackageAmount +
      totalRoomAmount +
      totalKidsAmount +
      totalInfantAmount +
      Number(
        boardingPrice(
          checkoutData?.getData?.travel_boarding_point?.boarding,
          checkoutData?.getData?.boardingType
        )
      ) *
      checkoutData?.getData?.old_travel_no_of_traveler || 0
    );
  };
  // let partiallink = loadAfterPay?.payLinkAmount;
  // const paytype = totalAmount + partiallink;
  // const responseValue = checkoutData?.getData?.old_travel_no_of_traveler;
  // const payLinkAmount = loadAfterPay?.payLinkAmount;
  let partiallink = loadAfterPay?.payLinkAmount;
  const paytype = totalAmount + partiallink;
  const responseValue = checkoutData?.getData?.old_travel_no_of_traveler;
  const payLinkAmount = loadAfterPay?.payLinkAmount;
  let status: any;
  if (checkoutData?.getData?.amountPaid + payLinkAmount >= finalPrice) {
    status = "Complete";
  } else {
    status = "Partial";
  }
  const makePayment = async (data: any) => {
    if (termsAgreed) {
      try {
        let totalAmountToSend = Number(loadAfterPay?.finalPrice);
        let amountPaid = totalAmount();

        if (paymentType === "Partial") {
          amountPaid = checkoutData?.applyTourbatchId[0]?.bookPrice;
          // totalAmountToSend = checkoutData?.applyTourbatchId[0]?.bookPrice;
        }

        let paymentObj = {
          amount_paid: payLinkAmount,
          bookingPrice: checkoutData?.applyTourbatchId[0]?.bookPrice,
          cgst: otherChareges?.[0]?.cgst,
          checkout_id: data?._id,
          id: objId,
          cusGSTNo: "",
          cuscompanyAddress: "",
          cuscompanyName: "",
          igst: otherChareges?.[0]?.igst,
          isOuter: true,
          partial_payment: checkoutData?.applyTourbatchId[0]?.bookPrice,
          // payment_status: amountPaid >= finalPrice ? "Complete" : "Partial",
          payment_status: status,
          sgst: otherChareges?.[0]?.sgst,
          total_amount: totalAmountToSend,
          username:
            checkoutData?.getData?.user_id?.firstname +
            " " +
            checkoutData?.getData?.user_id?.lastname,
          useremail: checkoutData?.getData?.user_id?.email,
          user_id: checkoutData?.getData?.user_id?._id,
          panNumber: panNumber,
          bookedBy: checkoutData?.getData?.user_id?._id,
          user_address: `${checkoutData?.getData?.user_id?.city}, ${checkoutData?.getData?.user_id?.state}`,

        };
        console.log(paymentObj);

        if (paymentType === "Partial") {
          paymentObj.amount_paid = checkoutData?.applyTourbatchId[0]?.bookPrice;
          paymentObj.payment_status = "Partial";
        }
        // return;
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/ccavRequestHandler`,
          paymentObj
        );
        // return;
        if (response.data.success) {
          window.location.href = response.data.encryptedRequest;
        } else {
          console.log("error");
        }
      } catch (error) {
        console.error("Error making payment:", error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Terms and Conditions",
        text: "Please accept the terms and conditions.",
      });
    }
  };

  const AllotherCharges = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/admin/get-all-other-charge`)
      .then((res) => {
        if (res.data.success) {
          setOtherCharges(res.data.data);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => console.log(err, "error in catch"));
  };

  const handleCheckboxChange = (event: any) => {
    setTermsAgreed(event.target.checked);
  };

  return (
    <>
      {loader ? <Loader /> : ""}
      <div className="container fnt">
        <div className="m-5 main_bx  p-2">
          <div className="header_pr  d-flex justify-content-center align-items-center text-danger ">
            <h1 className="pl-4 checkoutpage text_pink">Checkout</h1>
          </div>
          <div className="userdetail_pr p-3  d-flex justify-content-between align-items-center flex-wrap">
            <div className="respn detail_bx">
              <div className="d-flex justify-content-start  align-items-center">
                <p className="left_txt">Name</p>
                <p className="semicolon ">:</p>
                <p className="right_txt d-flex">
                  {loadAfterPay?.user_id?.firstname}{" "}
                  {loadAfterPay?.user_id?.lastname}
                </p>
              </div>
              <div className="d-flex justify-content-start  align-items-center">
                <p className="left_txt">Mob. No.</p>
                <p className="semicolon">:</p>
                <p className="right_txt">{loadAfterPay?.user_id?.mobile}</p>
              </div>
            </div>
            <div className=" respn detail_bx">
              <div className="d-flex justify-content-start align-items-center">
                <p className="left_txt">Tour Name</p>
                <p className="semicolon">:</p>
                <p className="right_txt">{loadAfterPay?.tour_id?.title}</p>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <p className="left_txt">Date</p>
                <p className="semicolon">:</p>
                <p className="right_txt">{loadAfterPay?.travel_date}</p>
              </div>
            </div>
            <div className=" respn detail_bx">
              <div className="d-flex justify-content-start align-items-center">
                <p className="left_txt">Email</p>
                <p className="semicolon">:</p>
                <p className="right_txt">{loadAfterPay?.user_id?.email}</p>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <p className="left_txt">No. of Traveller</p>
                <p className="semicolon">:</p>
                <p className="right_txt">
                  {loadAfterPay?.travel_no_of_traveler}
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className=" packagedetail_pr d-flex flex-wrap justify-content-start align-items-start">
            <div className="respn left_pkg  p-3  ">
              <div className="pkg_type">
                <div className="txt_head">Package Type</div>
                <div className="bottom_detail_bx">
                  {loadAfterPay &&
                    loadAfterPay.travel_packageType_data.length &&
                    loadAfterPay?.travel_packageType_data.map(
                      (o: any, index: number) =>
                        o.packPrice > 0 &&
                        o.persons > 0 && (
                          <div
                            className="d-flex justify-content-between align-items-center"
                            key={index}
                          >
                            <p className="pkg_left_txt">{o.packType}</p>
                            <p className="semicolon">:</p>
                            <p className="respn2 pkg_right_txt d-flex">
                              ₹{(o.packPrice || 0).toLocaleString("en-IN")} /
                              person X {o.persons} = ₹
                              {(o.packPrice * o.persons || 0).toLocaleString(
                                "en-IN"
                              )}
                            </p>
                          </div>
                        )
                    )}
                </div>
                <hr />
                {loadAfterPay?.child_price > 0 &&
                  loadAfterPay?.child_count > 0 && (
                    <div className="txt_head">Child</div>
                  )}
                {loadAfterPay?.child_price > 0 &&
                  loadAfterPay?.child_count > 0 && (
                    <div className="bottom_detail_bx">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="pkg_left_txt">
                          Kids (Age {loadAfterPay.tour_id?.childfrom}-
                          {loadAfterPay.tour_id?.childto})
                        </p>
                        <p className="semicolon">:</p>
                        <p className="pkg_right_txt d-flex">
                          ₹
                          {formatTotalAmount(
                            loadAfterPay?.child_price /
                            loadAfterPay?.child_count
                          )}{" "}
                          / person X {loadAfterPay?.child_count} = ₹
                          {formatTotalAmount(
                            (loadAfterPay?.child_price /
                              loadAfterPay?.child_count) *
                            loadAfterPay?.child_count
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                {loadAfterPay?.infant_price > 0 &&
                  loadAfterPay?.infant_count > 0 && (
                    <div className="bottom_detail_bx">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="pkg_left_txt">
                          Infant ({loadAfterPay?.tour_id?.infantfrom}-
                          {loadAfterPay?.tour_id?.infantto})
                        </p>
                        <p className="semicolon">:</p>
                        <p className="pkg_right_txt d-flex">
                          ₹{" "}
                          {formatTotalAmount(
                            loadAfterPay?.infant_price /
                            loadAfterPay?.infant_count
                          )}{" "}
                          / person X {loadAfterPay?.infant_count} = ₹
                          {formatTotalAmount(
                            (loadAfterPay?.infant_price /
                              loadAfterPay?.infant_count) *
                            loadAfterPay?.infant_count
                          )}
                        </p>
                      </div>
                    </div>
                  )}
              </div>

              {loadAfterPay?.travel_roomSharing_data &&
                loadAfterPay?.travel_roomSharing_data.length > 0 &&
                loadAfterPay?.travel_roomSharing_data.some(
                  (o: any) => o.roomPrice > 0 && o.persons > 0
                ) && (
                  <>
                    <div className="txt_head">Room Sharing</div>
                  </>
                )}

              {loadAfterPay &&
                loadAfterPay?.travel_roomSharing_data.length > 0 &&
                loadAfterPay?.travel_roomSharing_data.map(
                  (o: any, index: number) =>
                    o.roomPrice > 0 &&
                    o.persons > 0 && (
                      <div className="bottom_detail_bx" key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="pkg_left_txt">{o.roomType}</p>
                          <p className="semicolon">:</p>
                          <p className="pkg_right_txt d-flex">
                            ₹{(o.roomPrice || 0).toLocaleString("en-IN")} /
                            person X {o.persons} = ₹
                            {(o.roomPrice * o.persons || 0).toLocaleString(
                              "en-IN"
                            )}
                          </p>
                        </div>
                      </div>
                    )
                )}
              {checkoutData?.getData?.travel_boarding_point?.boarding &&
                checkoutData?.getData?.old_travel_no_of_traveler > 0 && (
                  <div>
                    <div className="txt_head">Boarding Point</div>
                    <div className="bottom_detail_bx">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="pkg_left_txt">
                          {
                            checkoutData?.getData?.travel_boarding_point
                              ?.boarding
                          }
                        </p>
                        <p className="semicolon">:</p>
                        <p className="pkg_right_txt d-flex">
                          ₹
                          {formatTotalAmount(
                            isNaN(
                              boardingPrice(
                                checkoutData?.getData?.travel_boarding_point
                                  ?.boarding,
                                checkoutData?.getData?.boardingType
                              )
                            )
                              ? 0
                              : boardingPrice(
                                checkoutData?.getData?.travel_boarding_point
                                  ?.boarding,
                                checkoutData?.getData?.boardingType
                              )
                          )}{" "}
                          / person X{" "}
                          {checkoutData?.getData?.old_travel_no_of_traveler} = ₹
                          {formatTotalAmount(
                            isNaN(
                              boardingPrice(
                                checkoutData?.getData?.travel_boarding_point
                                  ?.boarding,
                                checkoutData?.getData?.boardingType
                              ) *
                              checkoutData?.getData?.old_travel_no_of_traveler
                            )
                              ? 0
                              : boardingPrice(
                                checkoutData?.getData?.travel_boarding_point
                                  ?.boarding,
                                checkoutData?.getData?.boardingType
                              ) *
                              checkoutData?.getData
                                ?.old_travel_no_of_traveler
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              <hr />
              <div className=" bottom_detail_bx">
                <div className="d-flex justify-content-between  align-items-center ">
                  <p className="w-50 amount ">Total Amount</p>
                  <p className="amount d-flex">
                    ₹{formatTotalAmount(loadAfterPay?.finalPrice)}
                  </p>
                </div>
              </div>
              <hr />
            </div>
            <div className=" respn right_pkg p-3 ">
              <div className="pkg_type">
                <div className="txt_head">Summary</div>

                <div className="bottom_detail_bx">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="pkg_left_txt">Sub Total</p>
                    <p className="semicolon">:</p>
                    <p className="pkg_right_txt d-flex">
                      ₹
                      {Number(
                        loadAfterPay?.pricetourWithoutTaxes
                      ).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div className="bottom_detail_bx">
                {Number(SGSTamount) > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="pkg_left_txt">
                      SGST({otherChareges?.[0]?.sgst}%)
                    </p>
                    <p className="semicolon">:</p>
                    <p className="pkg_right_txt d-flex">
                      ₹{Number(SGSTamount).toFixed(2)}
                    </p>
                  </div>
                )}

                {Number(CGSTamount) > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="pkg_left_txt">
                      CGST({otherChareges?.[0]?.cgst}%)
                    </p>
                    <p className="semicolon">:</p>
                    <p className="pkg_right_txt d-flex">
                      ₹{Number(CGSTamount).toFixed(2)}
                    </p>
                  </div>
                )}

                {Number(IGSTamount) > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="pkg_left_txt">
                      IGST({otherChareges?.[0]?.igst}%)
                    </p>
                    <p className="semicolon">:</p>
                    <p className="pkg_right_txt d-flex">
                      ₹{Number(IGSTamount).toFixed(2)}
                    </p>
                  </div>
                )}

                {Number(TCSamount) > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="pkg_left_txt">
                      TCS({otherChareges?.[0]?.tcs}%)
                    </p>
                    <p className="semicolon">:</p>
                    <p className="pkg_right_txt d-flex">
                      ₹{Number(TCSamount).toFixed(2)}
                    </p>
                  </div>
                )}
              </div>

              <div className="bottom_detail_bx">
                <div className="d-flex justify-content-between align-items-center">
                  <p>Payable against link (Partial) </p>
                  <p>₹{loadAfterPay?.payLinkAmount.toLocaleString("en-IN")}</p>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <p className="w-50 amountf">Amount to be paid</p>
                  {/* <p>₹{loadAfterPay?.amountPaid.toLocaleString("en-IN")}</p> */}
                  <p>₹{loadAfterPay?.payLinkAmount.toLocaleString("en-IN")}</p>
                </div>
              </div>
              {domestincorInternational === "international" ? (
                <div className="col-md-4 lable col-lg-6 mt-3 mb-3">
                  <input
                    type="text"
                    className="form-control place2"
                    id="inputEmail4"
                    placeholder="PAN Number*"
                    value={panNumber}
                    onChange={handleChange}
                    maxLength={10}
                  />
                </div>
              ) : null}
              <div className="d-flex">
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    onChange={handleCheckboxChange}
                    checked={termsAgreed}
                  />
                  <label className="form-check-label d-flex">
                    <p className="text_pink mx-1">I have read agreed to all</p>{" "}
                    <a
                      href="#"
                      id="exampleModalToggleLabel"
                      onClick={toggleModal}
                    >
                      Terms and conditions
                    </a>
                  </label>
                </div>
              </div>
              <div className="m-4 d-flex justify-content-center align-items-center">
                <button
                  className=" payment_btn"
                  onClick={() => makePayment(loadAfterPay)}
                >
                  Make Payment
                </button>
              </div>
              <div className="border p-4 notes_txt">
                <div
                  className="fw-semibold"
                  dangerouslySetInnerHTML={{ __html: OrderNote }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <div
            className="modal fade show"
            id="exampleModalToggle"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabIndex={1}
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalToggleLabel">
                    Terms And Condition
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={toggleModal}
                    aria-label="Close"
                  ></button>
                </div>
                <div>
                  <ul>
                    <li>
                      All our bookings are on the basis of First come First
                      policy.
                    </li>
                    <li>
                      Bookings are non-transferable, non-adjustable or cannot be
                      rescheduled.
                    </li>
                    <li>
                      Trips can be rescheduled if informed 45 days prior the
                      trip (Subject to availability of preferred dates )
                    </li>
                    <li>
                      In case of cancellation of bookings from the consumer's
                      end, no booking amount shall be refunded or adjusted in
                      any case.
                    </li>
                    <li>
                      The photos/videos content created on glabol's trip (by
                      glabol's content creators or clients) is the property of
                      glabol & can also be used by glabol for advertising across
                      media platforms.
                    </li>
                    <li>
                      The IDs shall all be verified before boarding. No boarding
                      shall be entertained without a valid Govt. ID.
                    </li>
                    <li>
                      The Transfer of the bookings is not permitted. Only the
                      names mentioned at the time of confirmation shall be
                      allowed to travel.
                    </li>
                    <li>
                      Glabol reserve the rights to rearrange the itinerary/
                      transportations due to any kind of unavoidable events (i.e
                      weather condition, political condition, local union fights
                      or any others).
                    </li>
                    <li>
                      Glabol reserve the rights to rearrange the itinerary/
                      transportations due to any kind of unavoidable events (i.e
                      weather condition, political condition, local union fights
                      or any others).
                    </li>
                    <li>
                      An original individual identification proof(Aadhar card,
                      Driving License, Voter ID, Passport must be carried by the
                      traveler during the journey.
                    </li>
                    <li>
                      Glabol will not be responsible for any man made or natural
                      calamity. All the sports are safe and approved by the
                      state ministry, activities performed will be under the
                      guidance of highly trained professionals. However, in case
                      of any injury or accident during the adventure sport and
                      travel Glabol will not be liable.
                    </li>
                    <li>
                      In case of any updates in the booking, the consumer has to
                      give a prior notice to Glabol.
                    </li>
                    <li>
                      In case of inappropriate weather or any change in local
                      government norms, activities mentioned above can be
                      scheduled or canceled without any prior notice.
                    </li>
                    <li>
                      Any mischie for inappropriate attitude will not be
                      tolerated and Glabol reserves all rights to cancel such
                      bookings without any refund at any time in between the
                      trip.
                    </li>
                    <li>
                      Glabol can cancel the trip or individual booking without
                      any prior notice.
                    </li>
                    All Rights Reserved by Glabol India.
                  </ul>

                  <div className="modal-footer border-0">
                    <button
                      className="btn btn-color m-auto mb-2 "
                      style={{
                        padding: "7px 37px",
                        fontSize: "20px",
                        borderRadius: "10px",
                        background: "#ffA923",
                        margin: "4px 2px",
                        color: "#FFF",
                      }}
                      onClick={toggleModal}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PayAfter;
